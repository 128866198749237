import axios from 'axios';

const getDefaultState = () => {
    return {
        journeyTemplates: [],
        journeyReusableTemplates: [],
    }
};

const state = getDefaultState();

const getters = {
    getJourneyTemplates: state => state.journeyTemplates,
    getJourneyReusableTemplates: state => state.journeyReusableTemplates,
}

const actions = {

    async list({ commit, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey-reusable-templates/list', payload, config);

            commit('SET_JOURNEY_TEMPLATES', response.data.journeyTemplates);

        }
        catch (err) {
            // throw new Error(err);
        }
    },

    async listReusableTemplates({ commit, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey-reusable-templates/list-reusable-templates', payload, config);

            commit('SET_JOURNEY_REUSABLE_TEMPLATES', response.data.journeyReusableTemplates);

        }
        catch (err) {
            // throw new Error(err);
        }
    },

    async addToPendingReusableTemplates({ commit, dispatch, rootState }, payload) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                "JourneyID": payload.ID,
                "Name": payload.Name,
                "Description": payload.Description,
                "AuthorID": payload.AuthorID,
                "CategoryID": payload.CategoryID,
                "Picture": payload.Picture,
                "Overview": payload.Overview,
                "Structure": payload.Structure
            }

            await axios.post('/v2/journey-reusable-templates/add-to-pending', payloadAPI, config);

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey successfully added to pending reusable templates'
            }, {root: true});

        }
        catch (err) {

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey failed to be added to pending reusable templates'
            }, {root: true});
        }
    },

    async updateJourneyTemplateStatus({ commit, dispatch, rootState }, payload) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                "ID": payload.ID,
                "Reusable": payload.Reusable
            }

            await axios.post('/v2/journey-reusable-templates/update-template-status', payloadAPI, config);

            commit('UPDATE_JOURNEY_TEMPLATE_STATUS', payloadAPI);

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey reusable template status successfully updated'
            }, {root: true});

        }
        catch (err) {

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey reusable template status failed to be updated'
            }, {root: true});
        }
    },

}

const mutations = {

    SET_JOURNEY_TEMPLATES(state, journeyTemplates) {
        state.journeyTemplates = journeyTemplates;
    },

    SET_JOURNEY_REUSABLE_TEMPLATES(state, journeyReusableTemplates) {
        state.journeyReusableTemplates = journeyReusableTemplates;
    },

    UPDATE_JOURNEY_TEMPLATE_STATUS(state, payload) {
        const index = state.journeyTemplates.findIndex((journeyTemplate) => journeyTemplate.ID === payload.ID);

        if (index !== -1) {
            state.journeyTemplates[index].Reusable = payload.Reusable;
        }
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
      },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
