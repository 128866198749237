<template>

  <transition name="fade">

    <div v-if="isDisplayed" class="terms-and-conditions">

      <div class="terms-and-conditions__content">

        <span class="title">Terms and Conditions of Use</span>

        <p>
          Welcome to the learning platform provided by COMMOVIS and hosted by TENANT. By accessing or using our platform, you agree to comply with and be bound by the following terms and conditions:
          <br>
          <br>
          1. User Conduct:
          Users must conduct themselves in a manner that is respectful and does not cause harm, harassment, or insult to others on the platform. Any behavior violating this principle should be promptly reported.
          <br>
          <br>
          2. Data Privacy and Storage:
          COMMOVIS will securely store data on the platform, including user information necessary for platform functionality. We employ security measures to safeguard stored data against unauthorized access or disclosure.
          <br>
          <br>
          3. Photo Sharing:
          Photos of individuals, groups, and activities may be captured and uploaded to the platform for sharing among users. By using the platform, users grant permission for the sharing of such photos, including on social media platforms. Users retain the right to revoke this permission at any time.
          <br>
          <br>
          4. Content Sharing:
          Users are strictly prohibited from sharing or distributing any content provided by TENANT (or authors of programs, i.e., learning paths) without prior authorization. Any unauthorized distribution may result in legal action by TENANT, with jurisdiction determined by TENANT.
          <br>
          <br>
          5. Limitation of Liability:
          COMMOVIS shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the platform, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.
          <br>
          <br>
          6. TENANT Responsibility:
          TENANT is responsible for monitoring user behavior on the platform to prevent harmful conduct, harassment, or insults. TENANT shall take appropriate action against users found to be in violation of these terms and conditions.
          <br>
          <br>
          7. User Responsibility:
          Users are solely responsible for their actions on the platform and must promptly report any instances of harmful behavior, harassment, or insults observed. Users agree to abide by all applicable laws and regulations while using the platform.
          <br>
          <br>
          8. Termination:
          Either party may terminate this agreement in the event of a material breach of contract or non-compliance with its terms. Upon termination, users must cease all use of the platform.
          <br>
          <br>
          9. Governing Law and Jurisdiction:
          This agreement shall be governed by and construed in accordance with the laws of Austria. Any disputes arising from or relating to this agreement shall be subject to the exclusive jurisdiction of the courts of Innsbruck, Austria.
          <br>
          <br>
          10. Dispute Resolution:
          Any disputes arising from this agreement shall be resolved through mediation or arbitration in accordance with the rules of the Austrian Chamber of Commerce Mediation and Arbitration Center in Innsbruck, Austria.
          <br>
          <br>
          11. Banning Policy:
          COMMOVIS reserves the right to suspend or terminate user access to the platform for violations of these terms and conditions or for any other reason deemed necessary to maintain the integrity of the platform.
          <br>
          <br>
          By accessing or using the platform provided by COMMOVIS and hosted by TENANT, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.
        </p>

        <div class="buttons-wrapper">
          <button class="btn btn-primary agree-button" type="button" @click="agreeClickHandler()">Agree</button>
        </div>

      </div>

      <div class="backdrop"></div>

    </div>

  </transition>

</template>

<script>

import {ref} from "vue";

export default {

  name: 'TermsAndConditions',

  setup() {

    const isDisplayed = ref(false);

    const agreeClickHandler = () => {
      isDisplayed.value = false;
    };

    return {
      isDisplayed,
      agreeClickHandler,
    }
  },

}

</script>

<style scoped lang="scss">

.terms-and-conditions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;

  &__content {
    background-color: rgba(232, 231, 231);
    width: 590px;
    max-height: 50%;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 18px;
    text-align: left;
    overflow: auto;
    cursor: default;

    .title {
      font-weight: bold;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

  }

  .backdrop {
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: default;
  }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>