import axios from 'axios'

const getDefaultState = () => {
    return {
        userNotifications: [],
    }
};

const state = getDefaultState();

const getters = {
    getUserNotifications: state => state.userNotifications
}

const actions = {

    async fetchUserNotifications({commit, dispatch, rootState}) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {

            };

            const response = await axios.post(`/v2/user-notifications/get`, payloadAPI, config);

            commit('PUSH_USER_NOTIFICATIONS', response.data.userNotificationMessages);

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to fetch user notifications'
            }, {root: true});
        }

    },

    async removeUserNotification({commit, dispatch, rootState}, userNotificationID) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            await axios.delete(`/v2/user-notifications/${userNotificationID}`, config);

            commit('DELETE_USER_NOTIFICATION', userNotificationID);

        }
        catch (err) {
        }

    },

}

const mutations = {

    PUSH_USER_NOTIFICATIONS: (state, userNotifications) => {
        state.userNotifications = userNotifications;
    },

    DELETE_USER_NOTIFICATION: (state, userNotificationID) => {
        state.userNotifications = state.userNotifications.filter((userNotification) => {
            return userNotification.ID !== userNotificationID;
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
