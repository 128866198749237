const state = {
  isLoading: true
}

const getters = {
  getIsLoading: state => state.isLoading,
}

const actions = {
  setLoading({ commit }, status) {
    commit('setLoading', status)
  }
}
const mutations = {
  setLoading(state, status) {
    state.isLoading = status
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
