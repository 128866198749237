<template>
    <div class="mobile-information">Thank you for exploring our app! This is just a preview of what’s to come. We’re working hard to enhance our app and can’t wait to share the full experience with you. Stay tuned for exciting updates! <br>
      next steps: treasure chest, branding, profile section
      <br><br/>
      <button @click="goToDashboard">Go to Dashboard</button>
    </div>
</template>
<script>
import {onMounted, ref} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: 'MobileInformation',
    setup() {
        const hasContentLoaded = ref(false);
        const store = useStore();
        const router = useRouter();

const goToDashboard = () => {
    router.push('/m-dashboard/');
};
        onMounted(async () => {
            await store.dispatch('loading/setLoading', true);
            hasContentLoaded.value = true;
            await store.dispatch('loading/setLoading', false);
        });
        return {
      
      
      hasContentLoaded,
      goToDashboard

    }
    }
}
</script>
<style scoped lang="scss">
.mobile-information {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Ensure the container takes the full viewport height
  text-align: center; // Center the text horizontally
  padding: 2em;
}
</style>