<template>
  <div class="sidebar-container">
    <ul :class="{ top_menu_ul: isTopMenu }">
      <li @click="goTo('Dashboard')" :id="'home-' + activeId + '-' + isMyAccountMenu">
        <img src="../assets/icons/home.png" :class="{ top_menu: isTopMenu }">
        <span v-if="isTopMenu" class="menu-span">Home</span>
      </li>
      <li @click="goTo('LastJourneyView')" :id="'journey-' + activeId + '-' + isMyAccountMenu" :class="{ 'disabled-button': (lastJourneyID === '' || lastJourneyID === null || lastJourneyID === undefined) }">
        <font-awesome-icon icon="route"
                           class="font-awesome-icon"
                           :class="{ top_menu: isTopMenu }"
        />
        <span v-if="isTopMenu" class="menu-span">Journey</span>
      </li>
      <li @click="goTo('TreasureChest')" :id="'treasure-' + activeId + '-' + isMyAccountMenu">
        <img src="../assets/icons/chest.png" :class="{ top_menu: isTopMenu }">
        <span v-if="isTopMenu" class="menu-span">Treasure Chest</span>
      </li>
<!--      <li @click="goTo('Dashboard')">-->
<!--        <img src="../assets/icons/chat.png" :class="{ top_menu: isTopMenu }">-->
<!--        <span v-if="isTopMenu" class="menu-span">Chat</span>-->
<!--      </li>-->
<!--      <li @click="goTo('Dashboard')">-->
<!--        <img src="../assets/icons/approve.png" :class="{ top_menu: isTopMenu }">-->
<!--        <span v-if="isTopMenu" class="menu-span">Assignments</span>-->
<!--      </li>-->
    </ul>
  </div>
  <ConfirmationModal :isOpened="isUnsavedChangesModalOpened"
                     :title="titleModal"
                     :message="messageModal"
                     @confirm="confirmUnsavedChangesHandler"
                     @cancel="cancelUnsavedChangesHandler"
  />
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import router from "@/router";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default {
  emits: ['sidebarChange', 'routeChange'],
  components: {
    ConfirmationModal,
    FontAwesomeIcon
  },
  props: {
    isTopMenu: {
      type: Boolean,
      default: false
    },
    isMyAccountMenu: {
      type: Boolean
    }
  },
  setup(props, {emit}) {

    const store = useStore();

    const route = useRoute();

    const activeId = ref('');

    const isLogin = ref(false);

    const redirectRedtination = ref('');

    const titleModal = ref('Warning');

    const messageModal = ref('There are unsaved changes. Are you sure you want to leave this page?');

    const isUnsavedChangesModalOpened = ref(false);

    const lastJourneyID = computed(() => store.getters["userPreferences/getLastJourneyID"]);

    // const tenantColor = computed(() => {
    //   return {
    //     '--primary-color': store.state.tenant.tenantinfo.primarycolor
    //   }
    // });

    const cancelUnsavedChangesHandler = () => {
      isUnsavedChangesModalOpened.value = false;
    };

    const confirmUnsavedChangesHandler = () => {
      localStorage.removeItem('journeyCreationModified');
      isUnsavedChangesModalOpened.value = false;
      router.push({name: redirectRedtination.value});
    };

    const goTo = (destination) => {
      emit('routeChange', true);
      activeId.value = '';
      redirectRedtination.value = destination;
      const href = window.location.href;
      if (href.includes('journey-creation')) {
        const result = localStorage.getItem('journeyCreationModified');
        if (result === 'yes') {
          isUnsavedChangesModalOpened.value = true
        } else {
          router.push({name: destination}).then(() => {
            checkActiveRoute(window.location.href);
          });
        }
      }
      else if (destination === 'LastJourneyView' && lastJourneyID.value !== '') {
        router.push('/journey/view/' + lastJourneyID.value).then(() => {
          checkActiveRoute(window.location.href);
        });
      }
      else {
        router.push({name: destination}).then(() => {
          checkActiveRoute(window.location.href);
        });
      }
    };

    const checkActiveRoute = (url) => {
      if(url.includes('dashboard')){
        activeId.value = 'home';
      }
      if(url.includes('treasure-chest')){
        activeId.value = 'treasure';
      }
      if(url.includes('journey')){
        activeId.value = 'journey';
      }
    };

    const getActiveNavbarItem = (routePath) => {

      if (routePath === '/dashboard') {
        activeId.value = 'home';
      }
      else if (routePath.startsWith('/journey/map') || routePath.startsWith('/journey-creation') || routePath.startsWith('/journey/view')) {
        activeId.value = 'journey';
      }
      else if (routePath === '/treasure-chest') {
        activeId.value = 'treasure';
      }

    };

    watch(route, (to) => {
      getActiveNavbarItem(route.path);
    });

    onMounted(async () => {
      activeId.value = '';
      const url = window.location.href;
      checkActiveRoute(url);
      const location = url.substring(url.lastIndexOf('/') + 1);
      isLogin.value = location === 'logon';
    });

    return {
      // tenantColor,
      activeId,
      isLogin,
      goTo,
      messageModal,
      confirmUnsavedChangesHandler,
      cancelUnsavedChangesHandler,
      titleModal,
      isUnsavedChangesModalOpened,
      lastJourneyID,
    }
  }
}

</script>
<style lang="scss" scoped>
.sidebar-container {
  #home-home-false, #journey-journey-false, #treasure-treasure-false {
    border-bottom: 2px solid var(--primary-color);
  }
  ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    justify-content: right;
    align-items: center;
    display: flex;
    width: 100%;
    &.top_menu_ul {
      float: right;
      li {
        min-width: 10%;
        display: flex;
        flex-direction: column;
        text-align: center;
        width: fit-content;
        justify-content: center;
        align-items: center;
        padding: 0 3px;

        &.disabled-button {
          cursor: not-allowed;
          pointer-events: none;

          .top_menu {
            color: lightgrey;
            background-color: transparent;
          }

          .menu-span {
            color: lightgrey;
          }
        }

        &:hover {
          cursor: pointer
        }
      }
    }
  }

  li {
    .font-awesome-icon {
      height: 28px;
      padding: 2px;
      color: white;

      &.top_menu {
      color: black;
      }
    }

    img {
      width: auto;
      height: 32px;
      padding: 2px;
      filter: invert(1000);

      &.top_menu:not(.treasure_chest) {
        filter: invert(0);
      }
    }

    .menu-span {
      white-space: nowrap;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
    }
    span{
      font-size: 12px;
      color: black
    }
  }

}
</style>
