import axios from 'axios';

const getDefaultState = () => {
    return {
        containerMaxHeight: 0,
    }
};

const state = getDefaultState();

const getters = {
    getContainerMaxHeight: state => state.containerMaxHeight,
}

const actions = {

    setContainerMaxHeight({ commit}, containerMaxHeight) {

        commit('SET_CONTAINER_MAX_HEIGHT', containerMaxHeight);

    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {

    SET_CONTAINER_MAX_HEIGHT(state, containerMaxHeight) {
        state.containerMaxHeight = containerMaxHeight;
    },

    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
