const commovisconfig = {
  region: 'eu-west-1',
  default_tenant: 'commovis',
  default_base_api: 'https://9xd35yw450.execute-api.eu-west-1.amazonaws.com',
  default_full_uri: 'https://commovis.dev.commovis.com',
  dev_stage_base_api: 'https://api.dev.commovis.com',
  dev_stage_URLbase_end: '.dev.commovis.com',
  prod_stage_URLbase_end: '.commovis.com',
  prod_stage_base_api: 'https://api.commovis.com',
  redirect_tenant_url: 'https://www.rupertseewald.com'
}

export default commovisconfig
