<template>
  <div v-if="isConfigured && hasContentLoaded" :class="['logon-wrapper d-flex align-items-center min-vh-100', { 'logon-wrapper-mobile': isMobile }]" :style="tenantcolor">
    <div class="container h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12 text-center">
          <img v-if="tenantLogoURL" :src="tenantLogoURL" alt="Tenant Logo" class="tenant_logo" />
          <img v-else :src="commovisLogo" alt="Commovis" class="tenant_logo" />
          <Authenticator v-if="isConfigured && !isAuthenticated" :socialProviders="[]" :hideSignUp="true"  :formFields="formFields">
            <template v-slot="{ user, signOut }">
             <!--- <h1>Welcome {{ user.username }}!</h1>
              <button @click="signOut">Sign out</button> -->
            </template>
          </Authenticator>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useDevice } from '@/composables/useDevice'
import { Authenticator } from '@aws-amplify/ui-vue'
import { Hub } from '@aws-amplify/core'
//import { Amplify } from '@aws-amplify/core';
import '@aws-amplify/ui-vue/styles.css'
import commovisLogo from '@/assets/logo_long.png'
import { configAuthForLogin } from '@/services/auth'

export default {
  components: {
    Authenticator,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { isMobile } = useDevice();
    const hasContentLoaded = ref(false);
    const isConfigured = ref(false);


    const isAuthenticated = computed(() => store.state.auth.loggedIn);
    const tenantLogoURL = ref(null);
    const tenantname = computed(() => store.state.tenant.tenantinfo.title);
    const tenantcolor = computed(() => ({
      '--primary-color': store.state.tenant.tenantinfo.primarycolor
    }));
    const tenantwelcome = computed(() => 'Sign in to ' + tenantname.value);

    const getTenantLogoURL = async() => {
      if (store.state.tenant.tenantinfo.logo) {
        tenantLogoURL.value = await store.dispatch('file/getTenantLogoFile');
      } else {
        tenantLogoURL.value = null;
      }
    };

    //console.log('isAuthenticated value check in Logon.vue:', isAuthenticated.value);
    if (isAuthenticated.value) {
      router.push({ name: isMobile.value ? 'MobileDashboard' : 'Dashboard' });
    }

    const formFields = {
      signIn: {
        username: {
          label: 'Email',
          placeholder: 'Enter your email',
          isRequired: true,
          errorMessage: {
            empty: 'Email is required',
            invalid: 'Please enter a valid email address',
          },
        },
      }
    };

    onMounted(async () => {
      await store.dispatch('tenant/getTenant');
      await configAuthForLogin(); // Configure Amplify for login

      isConfigured.value = true;
      
      await getTenantLogoURL();
      await store.dispatch('loading/setLoading', false);
      hasContentLoaded.value = true;
      

      Hub.listen('auth', (data) => {
    if (data.payload.event === 'signedIn') {
      const redirectRoute = router.currentRoute.value.query.redirect || (isMobile.value ? 'MobileDashboard' : 'Dashboard');
      router.push({ name: redirectRoute });
    }
  });
    });
     // Watch for changes in authentication status
     watch(isAuthenticated, (newValue) => {
     //console.log('isAuthenticated changed to:', newValue);
     // console.log('The user signed in now!');
     if (newValue) {
    const redirectRoute = router.currentRoute.value.query.redirect || (isMobile.value ? 'MobileDashboard' : 'Dashboard');
    router.push({ name: redirectRoute });
  }
    });

    return {
      tenantname,
      tenantwelcome,
      tenantcolor,
      isAuthenticated,
      hasContentLoaded,
      tenantLogoURL,
      commovisLogo,
      isConfigured,
      formFields,
      isMobile,
    }
  }
}
</script>

<style lang="scss" scroped>
.logon-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
 
  
}
.logon-wrapper-mobile{
  max-width: 100%;
    padding: 10px;
    border-radius: 0;
    width:90vw;
}

.tenant_logo {
  margin-top: 8vh;
  margin-bottom: 5vh;
  width: 100%;
  max-width: 380px;
}


// Custom styles for Amplify UI components
:root {
  --amplify-colors-brand-primary-10: var(--primary-color);
  --amplify-colors-brand-primary-80: var(--primary-color);
  --amplify-colors-brand-primary-90: var(--primary-color);
  --amplify-colors-brand-primary-100: var(--primary-color);
  --amplify-components-button-primary-background-color: var(--primary-color);
  --amplify-components-button-primary-hover-background-color: var(--primary-color);
  --amplify-components-button-primary-focus-background-color: var(--primary-color);
}

.amplify-authenticator {
  --amplify-colors-background-secondary: white;
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  overflow: hidden;

 
 

}


.amplify-button[data-variation='primary'] {
  background-color: var(--primary-color) ;
  border-color: var(--primary-color) ;
}
.amplify-button[data-variation='primary']:hover,
.amplify-button[data-variation='primary']:focus {
  background-color: var(--primary-color);
  opacity: 0.9;
}

.amplify-button[data-variation='link'] {
  //background-color: var(--primary-color) !important;
  color: var(--primary-color) ;
}
.amplify-button[data-variation='link']:hover,
.amplify-button[data-variation='link']:focus {
  color:#ffffff ;
  background-color: var(--primary-color);
  opacity: 0.2;
}




.amplify-input {
  border-color: #ccc;
}

.amplify-input:focus {
  border-color: var(--primary-color) !important;
}


</style>