import axios from 'axios'

const getDefaultState = () => {
    return {
        userPreferences: {
            ID: null,
            UserID: null,
            Language: 'en',
            TreasureChestFilters: {
                Types: [],
                Sources: [],
                Tags: []
            },
            Tags: [],
            JourneysNotes: [],
            LastJourneyID: '',
            ExtraAttributes: {}
        },
    }
};

const state = getDefaultState();

const getters = {
    getLanguage: state => state.userPreferences.Language,
    getTags: state => state.userPreferences.Tags,
    getLastJourneyID: state => state.userPreferences.LastJourneyID,
    getUserExtraAttributes: state => state.userPreferences.ExtraAttributes,
}

const actions = {

    async fetchUserPreferences({commit, dispatch, rootState}) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {

            };

            const response = await axios.post(`/v2/user-preferences/get`, payloadAPI, config);

            commit('PUSH_USER_PREFERENCES', response.data.userPreferences);

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to fetch user preferences'
            }, {root: true});
        }

    },

    async updateLanguage({commit, dispatch, state, rootState}, language) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                ID: state.userPreferences.ID,
                Language: language
            };

            await axios.post(`/v2/user-preferences/update-language`, payloadAPI, config);

            commit('UPDATE_LANGUAGE', language);

        }
        catch (err) {
            commit('UPDATE_LANGUAGE', language);
        }

    },

    async updateTreasureChestFilters({commit, dispatch, state, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                ID: state.userPreferences.ID,
                TreasureChestFilters: payload.TreasureChestFilters
            };

            await axios.post(`/v2/user-preferences/update-treasure-chest-filters`, payloadAPI, config);

            commit('UPDATE_TREASURE_CHEST_FILTERS', payload.TreasureChestFilters);

        }
        catch (err) {
            commit('UPDATE_TREASURE_CHEST_FILTERS', payload.TreasureChestFilters);
        }

    },

    async updateTags({commit, dispatch, state, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let existingTags = JSON.parse(JSON.stringify(state.userPreferences.Tags));

            if (!existingTags.includes(payload.NewTag)) {
                existingTags.push(payload.NewTag);

                let payloadAPI = {
                    ID: state.userPreferences.ID,
                    Tags: existingTags
                };

                await axios.post(`/v2/user-preferences/update-tags`, payloadAPI, config);

                commit('UPDATE_TAGS', existingTags);
            }

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Something went wrong!'
            }, {root: true});
        }

    },

    async updateLastJourneyID({commit, dispatch, state, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };


            if (state.userPreferences.ID === null) {
                await dispatch('fetchUserPreferences');
            }

            let payloadAPI = {
                ID: state.userPreferences.ID,
                LastJourneyID: payload.LastJourneyID
            };

            await axios.post(`/v2/user-preferences/update-last-journey-id`, payloadAPI, config);

            commit('UPDATE_LAST_JOURNEY_ID', payload.LastJourneyID);

        }
        catch (err) {
            commit('UPDATE_LAST_JOURNEY_ID', payload.LastJourneyID);
        }

    },

    async updateJourneysNotes({commit, dispatch, state, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                ID: state.userPreferences.ID,
                JourneysNotes: payload.JourneysNotes
            };

            await axios.post(`/v2/user-preferences/update-journeys-notes`, payloadAPI, config);

            commit('UPDATE_JOURNEYS_NOTES', payload.JourneysNotes);

        }
        catch (err) {
            commit('UPDATE_JOURNEYS_NOTES', payload.JourneysNotes);
        }

    },

    async updateUserExtraAttributes({commit, dispatch, state, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let stateExtraAttributes = {};

            if (state.userPreferences.ExtraAttributes !== undefined) {
                stateExtraAttributes = JSON.parse(JSON.stringify(state.userPreferences.ExtraAttributes));
            }

            Object.assign(stateExtraAttributes, payload);

            let payloadAPI = {
                ID: state.userPreferences.ID,
                ExtraAttributes: stateExtraAttributes
            };

            await axios.post(`/v2/user-preferences/update-user-extra-attributes`, payloadAPI, config);

            commit('UPDATE_EXTRA_ATTRIBUTES', stateExtraAttributes);

        }
        catch (err) {
            commit('UPDATE_EXTRA_ATTRIBUTES', payload.ExtraAttributes);
        }

    },
    async updateUserExtraAttributesStripeCustomerID({commit, dispatch, state, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let stateExtraAttributes = {};

            if (state.userPreferences.ExtraAttributes !== undefined) {
                stateExtraAttributes = JSON.parse(JSON.stringify(state.userPreferences.ExtraAttributes));
            }

            Object.assign(stateExtraAttributes, payload);

            let payloadAPI = {
                ID: state.userPreferences.ID,
                ExtraAttributes: stateExtraAttributes
            };

            await axios.post(`/v2/user-preferences/update-user-extra-attributes`, payloadAPI, config);

            commit('UPDATE_EXTRA_ATTRIBUTES', stateExtraAttributes);

        }
        catch (err) {
            commit('UPDATE_EXTRA_ATTRIBUTES', payload.ExtraAttributes);
        }

    },

    // async addExistingUsersToUserPreferencesTable({commit, dispatch, rootState}) {
    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
    //
    //     try {
    //
    //         let config = {
    //             headers: {
    //                 Authorization: rootState.auth.bearer
    //             },
    //         };
    //
    //         let payloadAPI = {
    //
    //         };
    //
    //         const response = await axios.post(`/do-not-push/add-existing-users-to-user-preferences-table`, payloadAPI, config);
    //
    //         console.log(response.data.userPreferences);
    //
    //     }
    //     catch (err) {
    //     }
    // }

}

const mutations = {

    PUSH_USER_PREFERENCES: (state, userPreferences) => {
        state.userPreferences = userPreferences;
    },

    UPDATE_LANGUAGE: (state, language) => {
        state.userPreferences.Language = language;
    },

    UPDATE_TREASURE_CHEST_FILTERS: (state, treasureChestFilters) => {
        state.userPreferences.TreasureChestFilters = treasureChestFilters;
    },

    UPDATE_TAGS: (state, journeysTags) => {
        state.userPreferences.Tags = journeysTags;
    },

    UPDATE_JOURNEYS_NOTES: (state, journeysNotes) => {
        state.userPreferences.JourneysNotes = journeysNotes;
    },

    UPDATE_LAST_JOURNEY_ID: (state, LastJourneyID) => {
        state.userPreferences.LastJourneyID = LastJourneyID;
    },
    UPDATE_EXTRA_ATTRIBUTES: (state, ExtraAttributes) => {
        state.userPreferences.ExtraAttributes = ExtraAttributes;
    },

    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
      },

    // DELETE_USER_NOTIFICATION: (state, userNotificationID) => {
    //     state.userNotifications = state.userNotifications.filter((userNotification) => {
    //         return userNotification.ID !== userNotificationID;
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
