import axios from 'axios';

const getDefaultState = () => {
    return {
        journeyCategories: [],
    }
};

const state = getDefaultState();

const getters = {
    getJourneyCategories: state => state.journeyCategories,
}

const actions = {

    async list({ commit, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey-category/list', payload, config);

            commit('SET_JOURNEY_CATEGORIES', response.data.journeyCategoriesList)

        }
        catch (err) {
            throw new Error(err);
        }
    },

    async create({ commit, dispatch, rootState }, newJourneyCategory) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payload = {
                "Name": newJourneyCategory.Name,
            }

            const response = await axios.post('/v2/journey-category/create', payload, config);

            commit('ADD_JOURNEY_CATEGORY', response.data.newJourneyCategory)

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey Category successfully created'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey Category failed to be created'
            }, {root: true});
            throw new Error(err);
        }
    },

    async update({ commit, dispatch, rootState }, updatedJourneyCategory) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payload = {
                "Name": updatedJourneyCategory.Name,
            }

            const response = await axios.put(`/v2/journey-category/${updatedJourneyCategory.ID}`, payload, config);

            commit('UPDATE_JOURNEY_CATEGORY', response.data.updatedJourneyCategory)

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey Category successfully updated'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey Category failed to be updated'
            }, {root: true});
            throw new Error(err);
        }
    },

    async delete({ commit, dispatch, rootState }, deletedJourneyCategoryID) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            await axios.delete(`/v2/journey-category/${deletedJourneyCategoryID}`, config);

            commit('DELETE_JOURNEY_CATEGORY', deletedJourneyCategoryID)

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey Category successfully deleted'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey Category failed to be deleted'
            }, {root: true});
            throw new Error(err);
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {

    SET_JOURNEY_CATEGORIES(state, allJourneyCategories) {
        state.journeyCategories = allJourneyCategories;
    },

    ADD_JOURNEY_CATEGORY(state, newJourneyCategory) {
        state.journeyCategories.push(newJourneyCategory);
    },

    UPDATE_JOURNEY_CATEGORY(state, updatedJourneyCategory) {
        const indexUpdatedJourneyCategory = state.journeyCategories.findIndex((journeyCategory) => journeyCategory.ID === updatedJourneyCategory.ID);

        if (indexUpdatedJourneyCategory !== -1) {
            state.journeyCategories.splice(indexUpdatedJourneyCategory, 1, updatedJourneyCategory);
        }
    },

    DELETE_JOURNEY_CATEGORY(state, deletedJourneyCategoryID) {
        state.journeyCategories = state.journeyCategories.filter((journeyCategory) => journeyCategory.ID !== deletedJourneyCategoryID);
    },

    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
