<template>
  <MainLayout>
    <template v-slot:header> </template>

    <template v-slot:sidebar>
      <!--<album v-for="album in albums" :key="album.id" :album="album" /> -->
    </template>

    <template v-slot:content>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-5 col-xl-4 my-5">
            <div class="text-center">
              <!-- Preheading -->
              <h6 class="text-uppercase text-muted mb-4">
                404 error
              </h6>

              <!-- Heading -->
              <h1 class="display-4 mb-3">
                There’s no page here 😭
              </h1>

              <!-- Subheading -->
              <p class="text-muted mb-4">
                Looks like you ended up here by accident?
              </p>

              <!-- Button -->
              <router-link :to="{ name: 'Dashboard' }" class="btn btn-lg btn-primary">
                Return
              </router-link>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </template>
  </MainLayout>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import MainLayout from '@/layouts/MainLayout'

export default {
  components: {
    MainLayout
  },
  setup() {
    const store = useStore()

    onMounted(() => {
      store.dispatch('loading/setLoading', false)
    })
    return {}
  },
  methods: {
    goBack() {
      return this.$router.back()
    }
  }
}
</script>

<style scoped></style>
