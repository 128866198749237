<template>
<div class="sidebar-container">
  <ul>
    <li>
      <router-link :to="{ name: 'Dashboard' }">
        <img src="../assets/icons/home.png">
        <p v-if="isMenuExpanded">Home</p>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'TreasureChest' }">
        <img src="../assets/icons/chest.png">
        <p v-if="isMenuExpanded">Treasure Chest</p>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Dashboard' }">
        <img src="../assets/icons/chat.png">
        <p v-if="isMenuExpanded">Chat</p>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Dashboard' }">
        <img src="../assets/icons/approve.png">
        <p v-if="isMenuExpanded">Assignments</p>
      </router-link>
    </li>
  </ul>
  <div class="arrow">
    <img src="../assets/icons/right-arrow.png" @click="expandMenu()">
  </div>
</div>
</template>
<script>
import {computed, onMounted, ref} from "vue";

export default {
  emits: ['sidebarChange'],
  setup(props, {emit}) {
    const tenantColor = computed(() => {
      return {
        '--primary-color': store.state.tenant.tenantinfo.primarycolor
      }
    });

    const isMenuExpanded = ref(false);

    function expandMenu () {
      isMenuExpanded.value = !isMenuExpanded.value;
      emit('sidebarChange', isMenuExpanded.value);

    }

    onMounted(async () => {

    });

    return {
      tenantColor,
      expandMenu,
      isMenuExpanded
    }
  }
}

</script>
<style lang="scss" scoped>
.sidebar-container {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {

      a {
        display: flex;
        align-items: center;
        margin: 10px 12px;
        color: white;
        text-decoration: none;
      }
      img {
        width: 26px;
        height: auto;
      }

      p {
        margin: 0 0 0 4px;
      }

      &:hover {
        cursor: pointer;
        img {
          width: 28px;
        }
      }
    }
  }
  .arrow {
    position: fixed;
    bottom: 55px; //50 px -> footer height
    margin-left: 12px;

    img {
      width: 26px;
      height: auto;
    }

    &:hover {
      cursor: pointer;
      img {
        width: 28px;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  p {
    font-size: small;
  }
}
</style>
