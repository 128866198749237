import axios from 'axios';

const getDefaultState = () => {
    return {
        tenantAmount: 0,
        userAmount: 0,
        userRoleCost: {
            Participant: 0,
            Facilitator: 0,
            TenantAdmin: 0,
        },
       // isReturnToBilling: false,
    }
};

const state = getDefaultState();

const getters = {
    getTenantAmount: state => state.tenantAmount,
    getUserAmount: state => state.userAmount,
    getUserRoleCost: state => state.userRoleCost,
   // getIsReturnToBilling: state => state.isReturnToBilling,
}

const actions = {

    async getAmount({ commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            let response = await axios.post('/v2/internal-currency/get-amount', payload, config);

            commit('SET_AMOUNT', response.data)

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to fetch amounts'
            }, {root: true});
        }
    },

    // async addAmount({ commit, dispatch, rootState }, payload) {
    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
    //
    //     try {
    //         let config = {
    //             headers: {
    //                 Authorization: rootState.auth.bearer
    //             },
    //         };
    //         let payloadAPI = {
    //             AmountToAdd: payload.AmountToAdd,
    //             WhereToAdd: payload.WhereToAdd
    //         };
    //
    //         await axios.post('/v2/internal-currency/add-amount', payloadAPI, config);
    //
    //         dispatch('notifications/addNotification', {
    //             type: 'success',
    //             message: 'Commovis Credits updated successfully'
    //         }, {root: true});
    //
    //     }
    //     catch (err) {
    //         dispatch('notifications/addNotification', {
    //             type: 'error',
    //             message: 'Commovis Credits could not be updated successfully'
    //         }, {root: true});
    //         throw new Error(err);
    //     }
    // },

    // async getPaymentLink({commit, dispatch, rootState}, payload) {

    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

    //     try {
    //         let config = {
    //             headers: {
    //                 Authorization: rootState.auth.bearer
    //             },
    //         };
    //         let payloadAPI = {
    //             NumberOfCredits: payload.NumberOfCredits,
    //             RedirectLinkSuccess: payload.RedirectLinkSuccess,
    //             RedirectLinkCancel: payload.RedirectLinkCancel,
    //             TenantLink: payload.TenantLink,
    //         };

    //        const response = await axios.post('/v2/internal-currency/get-payment-link', payloadAPI, config);

    //        return response.data.URL;

    //     }
    //     catch (err) {
    //         dispatch('notifications/addNotification', {
    //             type: 'error',
    //             message: 'Something went wrong in the payment process'
    //         }, {root: true});
    //         throw new Error(err);
    //     }

    // },
    async getInvoiceLink({commit, dispatch, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
        //console.log('getInvoiceLink');
        //console.log(payload);
        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payloadAPI = {
                customerId: payload.customerId,
                productName: payload.productName,
                price: payload.price,
                quantity: payload.quantity,
                taxCode: payload.taxCode,
                returnUrl: payload.returnUri,
                billing_type: payload.billing_type,
            };

           const response = await axios.post('v2/ic/invoice-create', payloadAPI, config);
           return {
            invoiceUrl: response.data.invoiceUrl,
            customerPortalUrl: response.data.customerPortalUrl
        };

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Something went wrong with the invoice process'
            }, {root: true});
            throw new Error(err);
        }

    },
    async getPortalLink({commit, dispatch, rootState}, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
        //console.log('getPortalLink');
        //console.log(payload);
        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payloadAPI = {
                customerId: payload.customerId,
                email: payload.email,
                returnUri: payload.returnUri
            };

           const response = await axios.post('v2/ic/customer-manage', payloadAPI, config);
           const {
            billingPortalUrl,
            customer_country,
            customer_name
        } = response.data;

           if (customer_country && customer_name) {
            return billingPortalUrl;
        } else {
            return billingPortalUrl + '/customer/update';
        }

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Something went wrong with the billing portal'
            }, {root: true});
            throw new Error(err);
        }

    },
    async getLedgerEntry({commit, dispatch, rootState}, payload) {
        
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
        //console.log('getLedgerEntry Payload');
        //console.log(payload);
        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payloadAPI = {
                billing_type: payload.billing_type
            };

           const response = await axios.post('v2/ic/ledgerentry', payloadAPI, config);
           //console.log('getLedgerEntry Response');
           //console.log(response.data);
           return response.data;

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Something went wrong with the Credit Ledger entry'
            }, {root: true});
            throw new Error(err);
        }

    },

    async transferUserCommovisCredits({ commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payloadAPI = {
                ReceiverUserID: payload.ReceiverUserID,
                ReceiverUserFullName: payload.ReceiverUserFullName,
                SenderUserFullName: payload.SenderUserFullName,
                NumberOfTransferredCommovisCredits: payload.NumberOfTransferredCommovisCredits,
            };

            await axios.post('/v2/internal-currency/transfer-user-commovis-credits', payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Transfer of CCs done successfully'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to transfer CCs'
            }, {root: true});
        }
    },

    async getUserInternalCurrencyTransfers({ commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payloadAPI = {

            };

            const response = await axios.post('/v2/internal-currency/get-user-internal-currency-transfers', payloadAPI, config);

            return response.data.userInternalCurrencyTransfers;

        }
        catch (err) {

        }
    },


    // async onPaymentSuccess({commit, dispatch, rootState}, payload) {

    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

    //     try {
    //         let config = {
    //             headers: {
    //                 Authorization: rootState.auth.bearer
    //             },
    //         };
    //         let payloadAPI = {
    //             SessionID: payload.SessionID,
    //             FromPage: payload.FromPage,
    //             InvoiceItemsAmount: payload.InvoiceItemsAmount,
    //             InvoiceItemsCountry: payload.InvoiceItemsCountry,
    //             TenantLink: payload.TenantLink
    //         };

    //        const response = await axios.post('/v2/internal-currency/on-payment-success', payloadAPI, config);

    //        if (response.data !== null && response.data.hasOwnProperty('amount_to_add')) {
    //            dispatch('notifications/addNotification', {
    //                type: 'success',
    //                message: "🎉 Success! COMMOVIS Credits have been added to your account. Congratulations on uplifting your participants' learning experience—let's keep growing together!"
    //            }, {root: true});
    //        }

    //         return response.data;

    //     }
    //     catch (err) {
    //         dispatch('notifications/addNotification', {
    //             type: 'error',
    //             message: "⚠️ Oops! Something went wrong with your payment. Please check your details and try again. We're here to help if you need it!"
    //         }, {root: true});
    //         throw new Error(err);
    //     }

    // },

    // async onPaymentCancel({commit, dispatch, rootState}) {

    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

    //     try {
    //         dispatch('notifications/addNotification', {
    //             type: 'error',
    //             message: "⚠️ Oops! Something went wrong with your payment. Please check your details and try again. We're here to help if you need it!"
    //         }, {root: true});
    //     }
    //     catch (err) {
    //         dispatch('notifications/addNotification', {
    //             type: 'error',
    //             message: "⚠️ Oops! Something went wrong with your payment. Please check your details and try again. We're here to help if you need it!"
    //         }, {root: true});
    //     }

    // },

    // async setIsReturnToBilling({commit, dispatch}, isReturnToBilling) {
    //     commit('SET_IS_RETURN_TO_BILLING', isReturnToBilling);
    // },

    // async populateInternalCurrencyTable({ commit, rootState}) {
    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
    //
    //     try {
    //         let config = {
    //             headers: {
    //                 Authorization: rootState.auth.bearer
    //             },
    //         };
    //         let payload = {
    //
    //         };
    //
    //         await axios.post('/do-not-push/populate-internal-currency-table', payload, config);
    //
    //     }
    //     catch (err) {
    //         throw new Error(err);
    //     }
    // },

}

const mutations = {
    SET_AMOUNT(state, data) {
        state.tenantAmount = data.tenantAmount;
        state.userAmount = data.userAmount;
        state.userRoleCost = data.userRoleCost;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
      },

    

    //SET_IS_RETURN_TO_BILLING(state, data) {
    //    state.isReturnToBilling = data;
    //},
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
