<template>

  <transition name="fade">

    <div class="confirmation-modal" v-if="isOpened">

      <div class="confirmation-modal__content">
        <h3>{{ title }}</h3>

        <p>{{ message }}</p>

        <div class="buttons-wrapper">
          <button class="btn btn-primary" type="button" @click="$emit('confirm')">Yes</button>
          <button class="btn btn-primary" type="button" @click="$emit('cancel')">No</button>
        </div>

      </div>

      <div class="backdrop" @click="$emit('cancel')"></div>

    </div>

  </transition>

</template>

<script>

export default {

  name: 'ConfirmationModal',

  props: {

    isOpened: {
      type: Boolean,
      default: false,
      required: false,
    },

    title: {
      type: String,
      required: false,
    },

    message: {
      type: String,
      required: false,
    },

  },

  emits: ['confirm', 'cancel'],

  setup() {

    return {}
  }

}

</script>

<style lang="scss" scoped>

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;

  &__content {
    background-color: rgba(232, 231, 231);
    width: 590px;
    max-height: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 18px;
    text-align: center;
    overflow: auto;

    h3 {
      font-weight: bold;
      text-transform: uppercase;
      cursor: default;
    }

    p {
      cursor: default;
      white-space: break-spaces;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

  }

  .backdrop {
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
