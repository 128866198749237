import axios from 'axios';

const getDefaultState = () => {
    return {

    }
};

const state = getDefaultState();

const getters = {

}

const actions = {

    async getTreasureChestForUser({commit, dispatch, rootState}) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payload = {
            };

            const response = await axios.post(`/v2/treasure-chest/get-treasure-chest-for-user`, payload, config);

            return response.data.treasureChestAssets;

        }
        catch (err) {
            // dispatch('notifications/addNotification', {
            //     type: 'error',
            //     message: 'Failed to assign memory card to user'
            // }, {root: true});
            // throw new Error(err);
        }

    },

    // async update({commit, dispatch, rootState}, payload) {
    //
    //     axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
    //
    //     try {
    //
    //         let config = {
    //             headers: {
    //                 Authorization: rootState.auth.bearer
    //             },
    //         };
    //
    //         let payloadAPI = {
    //             Assets: payload.Assets,
    //         };
    //
    //         if (payload.hasOwnProperty('JourneyID')) {
    //             payloadAPI.JourneyID = payload.JourneyID;
    //         }
    //         else {
    //             if (payload.hasOwnProperty('UsersIDs')) {
    //                 payloadAPI.UsersIDs = payload.UsersIDs;
    //             }
    //         }
    //
    //         await axios.post(`/treasure-chest/update`, payloadAPI, config);
    //
    //     }
    //     catch (err) {
    //         throw new Error(err);
    //     }
    // },


    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {

    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
