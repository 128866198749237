// src/utils/deviceUtils.js

export function isMobileDevice() {
    const ua = navigator.userAgent;
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isSmallScreen = window.matchMedia('(max-width: 600px)').matches;
    const isMobileUA = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);
  
    return isTouchDevice && (isSmallScreen || isMobileUA);
  }
  
  export function getOS() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
  
    if (/windows phone/i.test(ua)) {
      return 'Windows Phone';
    }
    if (/android/i.test(ua)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
      return 'iOS';
    }
    return 'Other';
  }
  
  export function checkPwaInstallation() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }