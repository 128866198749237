import { fetchAuthSession} from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';

import {   getCurrentUser, fetchDevices, fetchUserAttributes, updatePassword, signOut } from '@aws-amplify/auth';
//import { Amplify, Auth } from 'aws-amplify';
import store from '@/store';
import commovisconfig from '@/services/config.js';

//const Auth = new AuthClass();



export const AUTH_EVENTS = {
  SIGNED_IN: 'signedin',
  SIGNED_OUT: 'signedOut',
  SIGNED_UP: 'signUp',
  CONFIGURED: 'configured',
  COGNITOHOSTED_UI: 'cognitoHostedUI'
};
//ConsoleLogger.LOG_LEVEL = 'DEBUG';
//const logger = new ConsoleLogger('configAuth');


//logger.debug('Starting Amplify configuration');

export const configAuth = async () => {
  while (!store.state.tenant.tenantinfo || !store.state.tenant.tenantinfo.details) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  //console.log('Configuring Auth with:', store.state.tenant.tenantinfo.details);

  const awsConfig = {
    Cognito: {
      userPoolClientId: store.state.tenant.tenantinfo.details.CId,
      userPoolId: store.state.tenant.tenantinfo.details.Id,
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      loginWith: { // Optional
        username: 'false',
        email: 'true', // Optional
        phone: 'false', // Optional
      }
    }
  };

  //console.log('AWS Config:', JSON.stringify(awsConfig, null, 2));

  try {
    Amplify.configure({
      Auth: awsConfig
    });
    //console.log('Amplify configured successfully');
    //Amplify.Auth.configure(awsConfig);

    const currentConfig = Amplify.getConfig()
   //const currentAuthConfig = Amplify.Auth.configure(awsConfig)
 
    //console.log('Current Config:', JSON.stringify(currentConfig, null, 2));
    //console.log('Current Auth Config:', JSON.stringify(currentAuthConfig, null, 2));

 
    await checkCurrentAuthenticatedUser();

    return true;
  } catch (error) {
    console.error('Error configuring Amplify:', error);
    throw error;
  }
};
export const configAuthInitial = async () => {
  while (!store.state.tenant.tenantinfo || !store.state.tenant.tenantinfo.details) {
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  const awsConfig = {
    Cognito: {
      userPoolClientId: store.state.tenant.tenantinfo.details.CId,
      userPoolId: store.state.tenant.tenantinfo.details.Id,
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      loginWith: { // Optional
        username: 'false',
        email: 'true', // Optional
        phone: 'false', // Optional
      }
    }
  };

  try {
    Amplify.configure({
      Auth: awsConfig
    });
    return true;
  } catch (error) {
    console.error('Error configuring Amplify:', error);
    throw error;
  }
};

export const configAuthForLogin = async () => {
  try {
    await configAuthInitial();
    await checkCurrentAuthenticatedUser();
  } catch (error) {
    console.error('Error during login configuration:', error);
  }
};

export const checkCurrentAuthenticatedUser = async () => {
  try {
    const currentUser =  await getCurrentUser();
    await updateAuthStore(AUTH_EVENTS.SIGNED_IN, currentUser);
    await getAuthToken();

    return currentUser;

  } catch (error) {
    return null;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {

    const AuthUpdatePasswordInput = {
      newPassword: newPassword,
      oldPassword: oldPassword
      }
    const changePasswordResult = await updatePassword(AuthUpdatePasswordInput);
    //console.log('changePassword from auth.jsin changePassword:', changePassword);
    return changePasswordResult;

  } catch (error) {

    const errorString = error.toString();
  


     // Assuming error is a string in the format 'ErrorCode: ErrorMessage'
    let errorCode = 'UnknownError';
    let errorMessage = 'An unknown error occurred';

 
      const [code, ...messageParts] = errorString.split(':');
      errorCode = code.trim();
      errorMessage = messageParts.join(':').trim();
  

    throw {
      code: errorCode,
      message: errorMessage
    };
  }
};

export const handleAuthStateChange = async (event, data) => {

  switch (event) {
    case AUTH_EVENTS.SIGNED_IN:
      await updateAuthStore(AUTH_EVENTS.SIGNED_IN, data);
      break;
    case AUTH_EVENTS.COGNITOHOSTED_UI:
      await updateAuthStore(AUTH_EVENTS.SIGNED_IN, data);
      break;
    case AUTH_EVENTS.SIGNED_OUT:
      await updateAuthStore(AUTH_EVENTS.SIGNED_OUT);
      break;

  }
};

const updateAuthStore = async (authState, userData = null) => {

  switch (authState) {
    case AUTH_EVENTS.SIGNED_IN:

      const session = await fetchAuthSession({ forceRefresh: true });
      const userAttributes = await fetchUserAttributes();

      const session_user_details = {
        userData: {
          ...userData,
          userAttributes: { ...userAttributes }
        },
        session: { ...session }
      };
  
      store.dispatch('auth/isAuthenticated', true);
      store.dispatch('auth/changeAuthState', 'signedin');
      store.dispatch('auth/setStoreUserDetails', session_user_details);
      store.dispatch('auth/setUserInfo', session_user_details?.userData?.userAttributes);
      await getAuthToken();
      
      break;
    case AUTH_EVENTS.SIGNED_OUT:
      await store.dispatch('resetAllStores');
      await store.dispatch('auth/isAuthenticated', false);
      store.commit('auth/setUser', null);
      store.commit('auth/setAuthState', 'signedOut');

      break;
    // Add other cases as needed
  }
};


export const signOutCMV = async () => {
  try {
    const signOutResult = await signOut();

    await updateAuthStore(AUTH_EVENTS.SIGNED_OUT);

    await store.dispatch('resetAllStores');

    return { status: 'ok', payload: {} };
  } catch (error) {
    console.error('Error signing out:', error);
    return { status: 'error', payload: error };
  }
};

//export const getCurrentUserCMV = async () => {
//  try {
//    const user = await getCurrentUser();
////    await updateAuthStore(AUTH_EVENTS.SIGNED_IN, user);
//    return user;
//  } catch (error) {
//    await updateAuthStore(AUTH_EVENTS.SIGNED_OUT);
//    return null;
//  }
//};

export const getAuthToken = async () => {
  try {

    const session = await fetchAuthSession({ forceRefresh: true });
    //console.log('Auth session for tocken:', session);
    


    const token = session.tokens?.accessToken?.toString();
    //console.log('Token:', token);
    //console.log('session',session.tokens?.accessToken?.payload?.exp)
    const unixTimestamp = session.tokens?.accessToken?.payload?.exp;
    if (unixTimestamp) {
      const expirationDate = new Date(unixTimestamp * 1000);
      //console.log(expirationDate.toLocaleString());
    }



    let bearerDetails = undefined;
    let bearerDetailsBase64 = undefined;
    let commovisToken = undefined;

    // create our own Bearer
    bearerDetails =
    store.state.tenant.tenantinfo.details.CId +
    '::' +
    store.state.tenant.tenantinfo.details.Id +
    '::' +
    store.state.tenant.tenantinfo.id

    bearerDetailsBase64 = btoa(bearerDetails);

    commovisToken =
    token +
    '::::' +
    bearerDetailsBase64



    store.dispatch('auth/getBearerDetails', `Bearer ${commovisToken}`);
    return token;
  } catch (error) {
    //console.error('Error getting auth token:', error);
    return null;
  }
};