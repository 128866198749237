<template>
  <slot name="header" />
  <div class="container">
    <div class="row">
      <div class="title text-center pb-4">
        <slot name="title" />
      </div>

      <div class="col-sm-3">
        <div class="sidebar">
          <slot name="sidebar" />
        </div>
      </div>
      <div class="col-sm-9">
        <div class="content">
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>
