<template>
  <transition name="notification-fade">
    <div v-if="allNotifications.length > 0" class="notifications-list">
      <NotificationMessage
          v-for="notification in allNotifications"
          :key="notification.id"
          :notification="notification"
      />
    </div>
  </transition>
</template>

<script>

import {computed} from "vue";
import { useStore } from 'vuex';
import NotificationMessage from "@/components/notifications/NotificationMessage.vue";

export default {

  name: "NotificationsList",

  components: {
    NotificationMessage,
  },

  setup() {

    const store = useStore();

    const allNotifications = computed(() => store.getters["notifications/getNotifications"]);

    return {
      allNotifications,
    }

  },

}
</script>

<style lang="scss" scoped>
.notification-fade-enter-active,
.notification-fade-leave-active {
  transition: all 0.5s;
}

.notification-fade-enter-from,
.notification-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
.notifications-list {
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0.2em;
  z-index: 10;
  border-radius: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

</style>
