<template>
  <div :class="typeClass" class="alert">
    <span>{{ notification.message }}</span>
  </div>
</template>

<script>

import { useStore } from 'vuex';
import {computed, ref} from "vue";

export default {

  name: "NotificationMessage",

  props: ['notification'],

  setup(props) {

    const store = useStore();

    const notification = ref();
    notification.value = props.notification;

    setTimeout(() => {
      store.dispatch('notifications/removeNotification', notification.value);
    }, 3000);

    const typeClass = computed(() => `alert_${notification.value.type}`);

    return {
      notification,
      typeClass,
    }

  },

}
</script>

<style lang="scss" scoped>

.alert {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  color: white;
  margin: auto;
  border-radius: 0;

  span {
    font-size: 20px;
    text-align: center;
  }

  &_success {
    background-color: #46a31c;
  }

  &_error {
    background-color: #d13602;
  }

}

</style>
