import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
let ls = new SecureLS({ isCompression: false })

// Modules
import auth from '@/store/modules/auth';
import tenant from '@/store/modules/tenant';
import loading from '@/store/modules/loading';
import users from '@/store/modules/users';
import journey from '@/store/modules/journey';
import journeyCategory from "@/store/modules/journeyCategory";
import notifications from '@/store/modules/notifications';
import layout from "@/store/modules/layout";
import file from "@/store/modules/file";
import treasureChest from "@/store/modules/treasureChest";
import reusableAssets from "@/store/modules/reusableAssets";
import userNotifications from "@/store/modules/userNotifications";
import userPreferences from "@/store/modules/userPreferences";
import journeyReusableTemplates from "@/store/modules/journeyReusableTemplates";
import internalCurrency from "@/store/modules/internalCurrency";
import tenantSpecificAssets from "@/store/modules/tenantSpecificAssets";
import cmv from "@/store/modules/cmv";

export default createStore({
  modules: {
    loading,
    auth,
    tenant,
    users,
    journey,
    journeyCategory,
    notifications,
    layout,
    file,
    treasureChest,
    reusableAssets,
    userNotifications,
    userPreferences,
    journeyReusableTemplates,
    internalCurrency,
    tenantSpecificAssets,
    cmv,
  },
  actions: {
    resetAllStores({ commit }) {
      commit('cmv/RESET_STATE');
      commit('file/RESET_STATE');
      commit('internalCurrency/RESET_STATE');
      commit('journey/RESET_STATE');
      commit('journeyCategory/RESET_STATE');
      commit('journeyReusableTemplates/RESET_STATE');
      commit('layout/RESET_STATE');
      commit('reusableAssets/RESET_STATE');
      //commit('tenant/RESET_STATE');
      commit('tenantSpecificAssets/RESET_STATE');
      commit('treasureChest/RESET_STATE');
      commit('userPreferences/RESET_STATE');
      commit('users/RESET_STATE');
      // commit RESET_STATE for other modules as needed
    },
  },
  plugins: [
    createPersistedState({
      key: 'commovis',
      paths: ['tenant.tenantinfo'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
})
