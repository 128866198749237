<template>
  <div class="overlay"></div>

  <div class="spanner" :style="tenantcolor">
    <div v-if="!mobile" class="loader"></div>
    <div v-if="mobile">
      <img
        class="tenant-logo pulsing"
        :src="tenantLogoBase64"
       
      />
      <!-- <img
      v-if="!tenantLogoBase64 && mobile"
      class="tenant-logo pulsing"
      :src="commovisLogo"
      alt="tenant logo"
    /> -->
      <p class="footer-text"> powered by COMMOVIS</p>
    </div>
    <p>{{ msg }}</p>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import commovisLogo from '@/assets/logo_long.png';

export default {
  props: {
    msg: {
      type: String,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = useStore();
    const tenantcolor = computed(() => ({
      '--primary-color': store.state.tenant.tenantinfo.primarycolor,
    }));
    const tenantLogoBase64 = computed(() => store.state.tenant.tenantinfo.tenantLogoBase64);

    onMounted(async () => {
      if (!tenantLogoBase64.value) {
        await store.dispatch('tenant/getTenantLogoBase64');
      }
    });

    return {
      tenantcolor,
      tenantLogoBase64,
      commovisLogo
    };
  },
};
</script>

<style scoped>
.spanner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
  text-align: center;
  z-index: 1000;
  visibility: visible;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  visibility: visible;
  z-index: 1000;
}

.tenant-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.pulsing {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: var(--primary-color);
  font-size: 10px;
  margin: 80px auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.footer-text {
  font-size: 0.5rem; /* Relative size */
  color: var(--primary-color);
}
</style>