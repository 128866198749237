import axios from 'axios';

const getDefaultState = () => {
    return {
        ExpeditionLogs: []
    }
};

const state = getDefaultState();

const getters = {
    getterExpeditionLogs: state => state.ExpeditionLogs,
}

const actions = {

    async getExpeditionLogs({ commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            const signedURL = await dispatch('file/getFile', {
                file_name: 'ExpeditionLogsSummary.json',
                folder_name: `expedition_logs`
            }, {root: true});

            const response = await fetch(signedURL);

            if (response.ok) {

                const data = await response.json();

                commit('SET_EXPEDITION_LOGS', data);
            }

        }
        catch (err) {
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {

    SET_EXPEDITION_LOGS(state, data) {
        state.ExpeditionLogs = data;
    },

    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
