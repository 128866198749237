import axios from 'axios'
// import commovisconfig from '@/services/config'
// import {use} from "chai";

const getDefaultState = () => {
    return {

    }
};

const state = getDefaultState();

const getters = {

}

const actions = {

    async createUsers({commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                NewUsers: payload,
            }

            await axios.post('/v2/create-users', payloadAPI, config);

        }
        catch (err) {

        }
    },

    async getUsersByRole({ commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                "Roles": payload.Roles
            }

            const response = await axios.post('/v2/list-users-by-role', payloadAPI, config);
            // commit('setExistingUsers', response.data.usersList);

            return response.data.users;

        }
        catch (err) {
            throw new Error(err);
        }


    },

    async getExistingUsers({ commit, dispatch, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;
        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payload = {

            }

            const response = await axios.post('/v2/list-users', payload, config);
            // commit('setExistingUsers', response.data.usersList);
            response.data.usersList.forEach((user) => {
                user.IsEdit = false;
            });

            return response.data.usersList;

        }
        catch (err) {
            throw new Error(err);
        }
    },

    async getUsersLinkedToCurrentUser({ commit, dispatch, rootState }, data) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payload = {

            };

            const response = await axios.post('/v2/get-participants-linked-to-author', payload, config);

            return response.data.linkedUsers;
            // commit('SET_LINKED_USERS', response.data.linkedUsers);

        }
        catch (err) {
            throw new Error(err);
        }
    },

    async deleteUsers({ commit, dispatch, rootState }, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "DeletedUsers": payload
            }

            await axios.post('/v2/delete-users', payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'User successfully deleted'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'User failed to be deleted'
            }, {root: true});
        }
    },

    async updateUsers({commit, dispatch, rootState}, payload) {

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "UpdatedUsers": payload,
            };

            await axios.post(`/v2/tenant-admin-update-users`, payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'User successfully updated'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'User failed to be updated'
            }, {root: true});
            throw new Error(err);
        }
    },

    async verifyUserEmail({rootState}) {

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payload = {

            }

            await axios.post('/v2/user/verifyUserEmail', payload, config);

            // dispatch('notifications/addNotification', {
            //     type: 'success',
            //     message: 'User successfully deleted'
            // }, {root: true});

        }
        catch (err) {
            // dispatch('notifications/addNotification', {
            //     type: 'error',
            //     message: 'User failed to be deleted'
            // }, {root: true});
            // throw new Error(err);
        }
    },

    async checkExistingUsers({commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                UsersEmails: payload.UsersEmails,
            }

            let response = await axios.post('/v2/check-existing-users', payloadAPI, config);

            return response.data.checkedUsers;

        }
        catch (err) {

        }
    },

    async alignUsersExpirationDate({commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                Users: payload.Users,
            }

            await axios.post('/v2/align-users-expiration-date', payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Users expiration date successfully updated'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Users expiration date failed to be aligned'
            }, {root: true});
        }

    },

    async resendUsersTemporaryPassword({commit, dispatch, rootState}, payload) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {

            };

            if (!payload.IsAdminAllUsers) {
                payloadAPI.UsersEmails = payload.UsersEmails;
            }

            await axios.post('/v2/resend-users-temporary-password', payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'User temporary password successfully resend'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'User temporary password failed to resend'
            }, {root: true});
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {

    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
