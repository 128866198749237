import axios from 'axios';

const getDefaultState = () => {
    return {

    }
};

const state = getDefaultState();

const getters = {
}

const actions = {

    async listReusableJourneysImages({ commit, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/reusable-assets/list-reusable-journeys-images', payload, config);

            return response.data.assetsKeys;

        }
        catch (err) {
            throw new Error(err);
        }
    },

    async addExistingImageToReusableJourneysImages({ commit, dispatch, rootState}, payload) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        let config = {
            headers: {
                Authorization: rootState.auth.bearer
            },
        };

        let payloadAPI = {
            source_key: payload.source_key,
            file_name: payload.file_name,
        };

        try {

            await axios.post('/v2/reusable-assets/add-existing-image-to-reusable-journeys-images', payloadAPI, config)

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Image successfully added to reusable assets'
            }, {root: true});

            commit('loading/setLoading', false, { root: true });


        } catch (err) {
            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Image failed to be added to reusable assets'
            }, {root: true});

            commit('loading/setLoading', false, { root: true })
            throw new Error(err);
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
}

const mutations = {

    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
