// src/composables/useDevice.js
import { ref, onMounted, onUnmounted, watchEffect } from 'vue';
import { isMobileDevice, getOS, checkPwaInstallation } from '@/services/deviceUtils';

export function useDevice() {
  const isMobile = ref(false);
  const os = ref('');
  const isPwaInstalled = ref(false);

  function updateDeviceInfo() {
    isMobile.value = isMobileDevice();
    os.value = getOS();
    isPwaInstalled.value = checkPwaInstallation();
  }

  onMounted(() => {
    updateDeviceInfo();
    window.addEventListener('resize', updateDeviceInfo);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateDeviceInfo);
  });

  // Initial update
  updateDeviceInfo();

  return {
    isMobile,
    os,
    isPwaInstalled,
  };
}